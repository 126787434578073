html,
body {
  height: 100%;
}
/* These styles disable body scrolling if you are using <ScrollView> */
body {
  overflow: hidden;
  max-width: 820px;
  margin: 0 auto !important;
}
/* These styles make the root element full-height */
#root {
  display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

@font-face {
  font-family: 'Muli-Black';
  src: url('../src/resource/fonts/Muli-Black.ttf') format('truetype');
  font-weight: normal;
}
@font-face {
  font-family: 'Muli-Bold';
  src: url('../src/resource/fonts/Muli-Bold.ttf') format('truetype');
  font-weight: normal;
}
@font-face {
  font-family: 'Muli-SemiBold';
  src: url('../src/resource/fonts/Muli-SemiBold.ttf') format('truetype');
  font-weight: normal;
}
@font-face {
  font-family: 'Muli-Regular';
  src: url('../src/resource/fonts/Muli-Regular.ttf') format('truetype');
  font-weight: normal;
}


@font-face {
  font-family: 'Mortise-Bold';
  src: url('../src/resource/fonts/Mortise-Bold.otf') format('opentype');
  font-weight: normal;
}
@font-face {
  font-family: 'Mortise-Light';
  src: url('../src/resource/fonts/Mortise-Light.otf') format('opentype');
  font-weight: normal;
}
@font-face {
  font-family: 'Mortise-Medium';
  src: url('../src/resource/fonts/Mortise-Medium.otf') format('opentype');
  font-weight: normal;
}
@font-face {
  font-family: 'Mortise-Regular';
  src: url('../src/resource/fonts/Mortise-Regular.otf') format('opentype');
  font-weight: normal;
}
@font-face {
  font-family: 'Mortise-X-Bold';
  src: url('../src/resource/fonts/Mortise-X-Bold.otf') format('opentype');
  font-weight: normal;
}
@font-face {
  font-family: 'Mortise-X-Light';
  src: url('../src/resource/fonts/Mortise-X-Light.otf') format('opentype');
  font-weight: normal;
}